<template>
  <b-card-code>
    <div>
      <b-form class="position-relative" @submit.prevent="onSubmit">
        <h2>Environment</h2>
        <div class="pb-3">
          <div style="width: 40%" class="background-checkbox my-1">
            <b-form-group label="HDR Environment" label-cols-lg="5">
              <b-form-checkbox
                @change="onClickCheckbox"
                v-model="selected_HDR_Environment"
              >
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div class="choose_hdr">
            <div class="hdr_background">
              <div v-if="selected_HDR_Environment">
                <b-form-file
                  accept=".hdr"
                  ref="file-input"
                  v-model="file_HDR_Environment"
                />
              </div>
            </div>
          </div>
        </div>
        <h2>Background</h2>
        <div style="display: flex; justify-content: space-between">
          <div class="row col-12 px-1 pt-1 mb-1">
            <b-form-radio-group
              v-model="selected"
              :options="options"
              class="mb-1"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-radio-group>
          </div>
        </div>
        <div v-if="selected == 'BACK_HDR'">
          <div style="width: 40%" class="background-checkbox my-1">
            <b-form-group label="HDR Background" label-cols-lg="5">
              <div style="display: flex; align-items: center;">
                <b-form-checkbox
                  v-model="selected_HDR_Background"
                  :disabled="!hasLicensePermission('background-environment', 'background_file')"
                >
                </b-form-checkbox>
                <a
                  v-if="!hasLicensePermission('background-environment', 'background_file')"
                  href="/pricing"
                  target="_blank"
                  title="Setting background hdr file for higher license"
                >
                  <feather-icon
                    class="cursor-pointer ml-1"
                    icon="AlertTriangleIcon"
                    size="20"
                    style="color: #F00"
                  />
                </a>
              </div>
            </b-form-group>
          </div>
          <div class="choose_hdr">
            <div class="hdr_background">
              <div v-if="selected_HDR_Background">
                <b-form-file
                  accept=".hdr"
                  ref="file-input"
                  v-model="file_HDR_Background"
                  class="mb-2"
                  :disabled="!hasLicensePermission('background-environment', 'background_file')"
                />
              </div>
            </div>
          </div>
        </div>
        <b-row v-if="selected == 'COLOR'" class="mb-2">
          <b-col md="6">
            <chrome-picker v-model="backgroundColor" />
          </b-col>
        </b-row>
        <b-row v-if="selected == 'IMAGE'">
          <b-col md="6">
            <div class="d-flex flex-column bd-highlight">
              <p>Current Background Image:</p>
              <b-span v-if="!remoteBackgroundImage" class="text-warning mb-2">
                You don't have any image yet
              </b-span>
              <b-img
                v-if="remoteBackgroundImage"
                :src="remoteBackgroundImage"
                style="object-fit: cover; margin: 10px; width: 50%; float: left"
              />
              <div style="display: flex;">
                <b-form-file
                  accept=".jpg,.png,.jpeg"
                  ref="file-input"
                  v-model="backgroundImage"
                  class="mb-2"
                  :disabled="!hasLicensePermission('background-environment', 'background_image')"
                />
                <a
                  v-if="!hasLicensePermission('background-environment', 'background_image')"
                  href="/pricing"
                  target="_blank"
                  title="Setting background image file for higher license"
                >
                  <feather-icon
                    class="cursor-pointer ml-1"
                    icon="AlertTriangleIcon"
                    size="20"
                    style="color: #F00"
                  />
                </a>
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-center">
          <b-button
            ref="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :disabled="busyButton"
          >
            Submit
          </b-button>
        </div>
        <b-overlay :show="clickCheckboxIvent" no-wrap @shown="onShown">
          <template #overlay>
            <b-card-text class="font-weight-bolder">
              Submit will destroy your current HDR Environment
            </b-card-text>
            <div class="d-flex justify-content-center">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                ref="dialog"
                variant="outline-danger"
                @click="onClickCheckboxCancel"
                class="mr-3"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                ref="dialog"
                variant="outline-success"
                @click="clickCheckboxIvent = false"
              >
                OK
              </b-button>
            </div>
          </template>
        </b-overlay>

        <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
          <template #overlay>
            <div
              v-if="processing"
              class="text-center p-3 bg-primary text-light rounded"
            >
              <feather-icon icon="UploadCloudIcon" size="20" />
              <div class="mb-2">Preservation...</div>
              <b-progress
                min="1"
                max="5"
                :value="counter"
                variant="success"
                height="6px"
                class="mx-n3"
              />
            </div>
            <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <b-card-text class="font-weight-bolder">
                Are you sure?
              </b-card-text>
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mr-3"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="onOK"
                >
                  OK
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
      </b-form>
    </div>
  </b-card-code>
</template>

<script>
import { Chrome } from "vue-color";

import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BOverlay,
  BForm,
  BCol,
  BRow,
  BProgress,
  BFormGroup,
  BFormFile,
  BCardText,
  BFormRadioGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { errorMessage } from "@/auth/utils";
export default {
  components: {
    BButton,
    BOverlay,
    BForm,
    BCol,
    BRow,
    BProgress,
    BFormGroup,
    BCardCode,
    BFormRadioGroup,
    BFormFile,
    BCardText,
    BFormCheckbox,
    "chrome-picker": Chrome,
  },
  directives: {
    Ripple,
  },
  computed: {
    licensePermissions() {
      return this.$store.getters["app/LICENSE_PERMISSIONS"]
    },
    settings() {
      return this.$store.state.Editor.cameraSetting;
    },
    editor() {
      return this.$store.state.Editor.editor;
    },
  },
  data() {
    return {
      editorIsLoaded: false,
      busy: false,
      busyButton: true,
      processing: false,
      clickCheckboxIvent: false,
      counter: 1,
      interval: null,
      selected_HDR_Environment: false,
      backgroundImage: null,
      remoteHdrPath: null,
      remoteBackgroundImage: null,
      backgroundColor: {hex:"#000"},
      remoteSettings: null,
      settings_json_s3_path: null,
      selected_HDR_Background: false,
      file_HDR_Background: null,
      file_HDR_Environment: null,
      selected: "BACK_HDR",
      options: [
        { item: "BACK_HDR", name: "File" },
        { item: "COLOR", name: "Color" },
        { item: "TRANSPARENT", name: "Transparent" },
        { item: "IMAGE", name: "Image" },
      ],
    };
  },
  beforeDestroy() {
    this.clearInterval();
  },
  setup() {
    const projectData = ref(null);

    store
      .dispatch("Project/loadProject", store.state.Project.project.id)
      .then((response) => {
        projectData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          projectData.value = undefined;
        }
      });

    return {
      projectData,
    };
  },
  methods: {
    hasLicensePermission(permission_type, permission_field) {
      // Look in license_permission.json to manage permission_type and permission_field
      try {
        return this.isLicenseChosen(this.licensePermissions[permission_type][permission_field].split(" "))
      } catch {
        return true
      }
    },
    isLicenseChosen(licenseList) {
      for (let license of licenseList) {
        if (license == this.projectData.project_license_type) {
          return true
        }
      }
      return false
    },
    onClickCheckbox() {
      if (this.remoteHdrPath && !this.selected_HDR_Environment) {
        this.clickCheckboxIvent = true;
      }
    },

    onClickCheckboxCancel() {
      this.selected_HDR_Environment = true;
      this.clickCheckboxIvent = false;
    },

    updateProcessing() {
      // Counter is going up from 1 to 5
      // Simulate an async request
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 5) {
          this.counter += 1;
        } else {
          this.clearInterval();
          this.$nextTick(() => {
            // eslint-disable-next-line
            this.busy = this.processing = false;
          });
        }
      }, 350);
    },

    async sendRequestUpdateHDREnvironmentAsync() {
      await store
        .dispatch("Editor/updateHDRFile", {
          file_HDR_Environment: this.file_HDR_Environment,
        })
        .catch((error) => {
          errorMessage(this.$toast, error);
        });
    },

    async sendRequestRemoveHDREnvironmentAsync() {
      await store.dispatch("Editor/removeHDRFile", 'hdr').catch((error) => {
        errorMessage(this.$toast, error);
      });
    },

    async sendRequestUpdateHDRBackgroundAsync() {
      await store
        .dispatch("Editor/updateHDRFile", {
          file_HDR_Background: this.file_HDR_Background,
        })
        .catch((error) => {
          errorMessage(this.$toast, error);
        });
    },

    async sendRequestRemoveHDRBackgroundAsync() {
      await store.dispatch("Editor/removeHDRFile", 'back').catch((error) => {
        errorMessage(this.$toast, error);
      });
    },

    async sendRequestRemoveBackgroundImageAsync() {
      await store.dispatch("Editor/removeBackgroundImageFile").catch((error) => {
        errorMessage(this.$toast, error);
      });
    },

    async sendRequestUpdateBackgroundImageAsync() {
      await store
        .dispatch("Editor/updateBackgroundImageFile", this.backgroundImage)
        .catch((error) => {
          errorMessage(this.$toast, error);
        });
    },

    clearFiles() {
      this.$refs["file-input"].reset();
    },

    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },

    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus();
    },

    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus();
    },

    onSubmit() {
      if (this.editorIsLoaded) {
        this.processing = false
        this.busy = true
        this.busyButton = true
      }
    },

    onCancel() {
      this.busy = false;
      this.busyButton = false
    },

    async onOK() {
      this.processing = true;
      this.$store.commit('app/SET_IS_PROJECT_UPDATING', true)
      this.counter = 1;
      const bgSettings = this.settings;

      if (this.selected_HDR_Environment && this.file_HDR_Environment) {
        await this.sendRequestUpdateHDREnvironmentAsync();
        bgSettings.environmentType = true;
      } else if (!this.selected_HDR_Environment && this.remoteHdrPath) {
        await this.sendRequestRemoveHDREnvironmentAsync();
      }

      if (this.selected == "BACK_HDR" && this.selected_HDR_Background && this.file_HDR_Background) {
        bgSettings.backgroundType = "HDR";
        await this.sendRequestUpdateHDRBackgroundAsync();
        await this.sendRequestRemoveBackgroundImageAsync();
      } else if (this.selected == "COLOR" && this.backgroundColor) {
        bgSettings.backgroundColor = this.backgroundColor;
        bgSettings.backgroundType = "COLOR";
        await this.sendRequestRemoveBackgroundImageAsync();
        await this.sendRequestRemoveHDRBackgroundAsync();
      } else if (this.selected == "TRANSPARENT") {
        bgSettings.backgroundType = "TRANSPARENT";
        await this.sendRequestRemoveBackgroundImageAsync();
        await this.sendRequestRemoveHDRBackgroundAsync();
      } else if (this.selected == "IMAGE" && this.backgroundImage) {
        bgSettings.backgroundType = "IMAGE";
        await this.sendRequestUpdateBackgroundImageAsync();
        await this.sendRequestRemoveHDRBackgroundAsync();
      }

      await this.setBackgroundSettings(bgSettings);
      this.updateProcessing();
      await this.loadEditorAsync();
      this.$store.commit('app/SET_IS_PROJECT_UPDATING', false)

      if (this.remoteBackgroundImage) {
        this.remoteBackgroundImage += "?rand" + Date.now(); // Date for reloading image
      }
    },

    async setBackgroundSettings(bgSettings) {
      await this.$store.commit("Editor/SET_CAMERA", bgSettings);
      await this.$store
        .dispatch("Editor/changeSetting", store.state.Editor.editor.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:
                "You have successfully update Background/Environment general setting",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          errorMessage(this.$toast, error);
        });
    },
    async loadEditorAsync() {
      await this.$store
        .dispatch("Editor/getEditor", store.state.Editor.editor.id)
        .then((res) => {
          this.remoteBackgroundImage = res.data.background_img_s3_path;
          this.settings_json_s3_path = res.data.settings_json_s3_path;

          if (res.data.environment_hdr_s3_path) {
            this.remoteHdrPath = res.data.environment_hdr_s3_path;
            this.selected_HDR_Environment = true;
          }

          if (res.data.background_hdr_s3_path) {
            this.selected_HDR_Background = true;
          }

          this.$store.commit("Editor/SET_EDITOR", res.data);
          this.$store
            .dispatch("Editor/setCameraSetting") // Fetch settings json file
            .then((response) => response.json())
            .then((data) => {
              this.$store.commit("Editor/SET_CAMERA", data);
            });
        })
      .catch((error) => {
        errorMessage(this.$toast, error);
      });

      const settingsData = await fetch(this.settings_json_s3_path)
        .then(function (response) {
          return response.json();
      })
      await this.checkBackgroundTypeAsync(settingsData)
      this.editorIsLoaded = true
      this.busyButton = false
    },

    async checkBackgroundTypeAsync(settingsData) {
      switch (settingsData.backgroundType) {
        case 'BACK_HDR':
          this.selected = 'BACK_HDR'
          break
        case 'COLOR':
          this.selected = 'COLOR'
          this.backgroundColor = settingsData.backgroundColor
          break
        case 'TRANSPARENT':
          this.selected = 'TRANSPARENT'
          break
        case 'IMAGE':
          this.selected = 'IMAGE'
          break
      }
    }
  },
  mounted() {
    this.loadEditorAsync();
  },
};
</script>

<style lang="scss">
.background-checkbox {
  margin: 30px 0;
  .form-row {
    legend {
      padding: 0 0 0 5px !important;
    }
  }
}

.code-toggler {
  display: none;
}

.choose_hdr {
  width: 55%;
  margin-top: 15px;
  .hdr_background {
    min-height: 59px;
  }
}

.pickerColor {
  width: 20%;
  padding-bottom: 20%;
  margin-bottom: 10px;
  border: 1px solid black;
}
</style>
